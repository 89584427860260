import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaFileWord } from "react-icons/fa";
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { Button } from '@mui/material';
import './CourseReport.css';

export default function CourseReport() {


    let { id } = useParams();

    const [tab, setTab] = useState("report");



    const [report, setReport] = useState(null)
    const [spinner, setSpinner] = useState(false);
    const [error, setError] = useState(false);

    const fetchData = async () => {
        setSpinner(true);
        fetch(`https://ubt-test-api.citrine.university/api/htmlTemplateCourseReport/by_lms/?id_lms=${id}`, {
            headers: {
                'Authorization': `token f6b32ff13a71f949effe70bea3ee9ab9abc5fbef`
            }
        }).then((response) => {

            if (!response.ok) {
                throw new Error(response)
            }

            return response.json()
        }).then((data) => {
            setSpinner(false);
            setReport(data)
        }
        ).catch((err) => {
            console.error(err);
            setSpinner(false);
            setError(err)

        });
    }

    useEffect(() => {
        fetchData()
    }, [])

    function exportDocumentToWORD(element, filename = '') {
        var preHtml = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>`;
        var postHtml = `</body></html>`;
        var html = preHtml + element + postHtml;

        var blob = new Blob(['\ufeff', html], {
            type: 'application/msword'
        });

        // Specify link url
        var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);

        // Specify file name
        filename = filename ? filename + '.doc' : 'document.doc';

        // Create download link element
        var downloadLink = document.createElement("a");

        document.body.appendChild(downloadLink);

        if (navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            // Create a link to the file
            downloadLink.href = url;

            // Setting the file name
            downloadLink.download = filename;

            //triggering the function
            downloadLink.click();
        }

        document.body.removeChild(downloadLink);
    }


    let jsxContent

    if (spinner) {
        return <CircularProgress color="inherit" />
    }
    if (error) {
        return <div style={{ textAlign: "center" }}>
            <h3>Oops!</h3>
            <p>Something went wrong !</p>
        </div>
    }

    let goalsMappingHtmlContent
    let reportHtmlContent

    if (!error && !spinner) {
        // if (report?.length === 0) {
        //     jsxContent = <div style={{ textAlign: "center" }}>
        //         <h3>Sorry!</h3>
        //         <p>This course has no associated documents !</p>
        //     </div>
        // } else {
                if (report?.html_template) {
                    reportHtmlContent = report?.html_template
                }
                if (report?.goals_mapping) {
                    goalsMappingHtmlContent = report?.goals_mapping
                }
            // }
    }
            jsxContent = <>
                <Grid container justifyContent="space-between">
                    <Grid item>

                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <span className={tab === "goals" ? "tab activeTab" : "tab"} > <Button style={{ color: "#4C3D8E", fontWeight: "bold" }} onClick={() => { setTab("goals") }}>Goals Mapping Report  </Button></span>
                            </Grid>
                            <Grid item>
                                <span className={tab === "report" ? "tab activeTab" : "tab"}><Button style={{ color: "#4C3D8E", fontWeight: "bold" }} onClick={() => { setTab("report") }}>Course Report</Button></span>
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item>
                        <IconButton
                            color="medium"
                            onClick={() => { exportDocumentToWORD(tab === "report" ? reportHtmlContent : goalsMappingHtmlContent, 'word-document') }}
                        >

                            <FaFileWord />

                        </IconButton>
                    </Grid>
                </Grid>

                <br />
                <br />


                <div style={{ border: "2px solid #aaaaaa", borderRadius: "4px", padding: "15px" }} dangerouslySetInnerHTML={{ __html: tab === "report" ? reportHtmlContent : goalsMappingHtmlContent }} ></div>
            </>




    return (
        <>
            {jsxContent}
        </>
    );
}
