import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import CourseReport from "./pages/CourseReport.jsx"

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to={`/report/course/_5630_1`} />} />
          <Route path="/report/course/:id" element={<CourseReport />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;